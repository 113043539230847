@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  background-color: #141624;
  font-family: "Outfit", sans-serif;
}

code {
  font-family: "Outfit", sans-serif;
}

h1, h2, h3, h4, ul {
  margin: 0;
  padding: 0;
}

/* Estilos para a barra de rolagem */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #6B50C5;
  border-radius: 5px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}
